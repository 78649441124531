import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { ONLY_PAYMENTS_ACCESS } from '@/constants/user';


Vue.use(Router)

const updateDoctor = (condition, ownData) => {
  if (condition) {
    if (!ownData) {
      return store.getters['clinic/firstDocWithData'];
    } else {
      return store.getters['clinic/doctor'];
    }
  } else {
    const me = store.getters['clinic/me'];
    if (me && me.role_has_own_data) {
      return store.getters['clinic/me'];
    } else {
      return null;
    }
  }
};

const router = new Router({
  mode: 'history',
  base: '/app/',
  //base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('./views/Dashboard.vue'),
      redirect: '/timeline',
      children: [
        {
          path: '/timeline/',
          component: () => import('./views/TimelineView.vue'),
          children: [
            {
              path: '',
              component: () => import('./views/Timeline.vue'),
            },
          ]
        },
        {
          path: '/patient',
          name: 'patient',
          component: () => import('./views/PatientView.vue'),
          redirect: '/patient/list',
          children: [
            {
              path: '/patient/list',
              component: () => import('./views/PatientList.vue'),
            },
            {
              path: '/patient/archived/list',
              component: () => import('./views/ArchivedPatientList.vue'),
            },
            {
              path: '/patient/banned/list',
              component: () => import('./views/BannedPatientList.vue'),
            },
            {
              path: '/patient/add',
              component: () => import('./views/PatientAdd.vue'),
            },
            {
              path: '/patient/:id',
              name: 'patient-details',
              component: () => import('./views/PatientDetails.vue'),
            },
            {
              path: '/patient/:id/appointment',
              component: () => import('./views/AppointmentAdd.vue'),
            },
            {
              path: '/patient/:id/appointment/:appointment',
              name: 'appointment-details',
              component: () => import('./views/AppointmentDetails.vue'),
            }
          ]
        },
        {
          path: '/profile',
          component: () => import('./views/ProfileView.vue'),
        },
        {
          path: '/doctor',
          component: () => import('./views/DoctorView.vue'),
          redirect: '/doctor/list',
          children: [
            {
              path: '/doctor/list',
              component: () => import('./views/DoctorList.vue'),
            }
          ]
        },
        {
          path: '/employee',
          component: () => import('./views/EmployeeListView'),
        },
        {
          path: '/registrator',
          name: 'registrator',
          component: () => import('./views/RegistratorView.vue'),
          redirect: '/registrator/list',
          children: [
            {
              path: '/registrator/list',
              component: () => import('./views/RegistratorList.vue'),
            },
            {
              path: '/registrator/add',
              component: () => import('./views/RegistratorAdd.vue'),
            },
            {
              path: '/registrator/:id',
              name: 'registrator-details',
              component: () => import('./views/RegistratorDetails'),
            }
          ]
        },
        {
          path: '/timetable',
          component: () => import('./views/ScheduleView.vue'),
          children: [
            {
              path: '',
              name: 'timetable',
              component: () => import('./views/Timetable.vue'),
            },
            {
              path: '/timetable/schedule/add',
              component: () => import('./views/ScheduleAdd.vue'),
            },
            {
              path: '/timetable/schedule/:id',
              name: 'schedule-details',
              component: () => import('./views/ScheduleDetails.vue'),
            },
            {
              path: '/timetable/absence/add',
              component: () => import('./views/AbsenceAdd.vue')
            },
            {
              path: '/timetable/absence/:id',
              name: 'absence-details',
              component: () => import('./views/AbsenceDetails.vue')
            }
          ]
        },
        {
          path: '/settings',
          component: () => import('./views/settings/SettingsView.vue')
        },
        {
          path: '/support',
          component: () => import('./views/SupportView.vue'),
          children: [
            {
              path: '',
              component: () => import('./views/SupportButtonsView.vue')
            },
            {
              path: '/support/faq',
              component: () => import('./views/SupportFAQView.vue')
            },
            {
              path: '/support/legal',
              component: () => import('./views/SupportLegalView.vue')
            },
            {
              path: '/support/documents',
              component: () => import('./views/SupportDocumentsView.vue')
            },
            {
              path: '/support/instructions',
              component: () => import('./views/SupportInstructionsView.vue')
            },
            {
              path: '/support/promotions',
              component: () => import('@/views/SupportPromotionsView.vue')
            },
            {
              path: '/support/promotions/:id',
              component: () => import('@/views/SupportPromotionDetails')
            }
          ]
        },
        {
          path: '/statistics',
          component: () => import('@/views/statistics/StatisticsView'),
          children: [
            {
              path: '',
              component: () => import("@/views/statistics/StatisticsGeneralView")
            },
            {
              path: '/statistics/details/:name',
              component: () => import("@/views/statistics/StatisticsDetailsView"),
            }
          ]
        },
        {
          path: '/communication',
          component: () => import("@/views/CommunicationView"),
        },
        {
          path: '/reserve',
          component: () => import("@/views/ReserveList"),
        },
      ]
    },
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        public: true,
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/PrivacyPolicy.vue'),
      meta: {
        public: true,
        onlyWhenLoggedOut: true
      }
    },
    // TODO: REMOVE COMMENTED LINES AFTER FULL-DEV-TESTS
    // {
    //   path: '/register',
    //   name: 'Registration',
    //   component: () => import('./views/auth/Register.vue'),
    //   meta: {
    //     public: true,  // Allow access to even if not logged in
    //     onlyWhenLoggedOut: true
    //   }
    // },
    {
      path: '/register/confirm/:token',
      name: 'Confirm registration',
      component: () => import('./views/RegisterConfirm.vue'),
      meta: {
        public: true,  // Allow access to even if not logged in
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('./views/auth/Login.vue'),
      meta: {
        public: true,
      }
    },
    // {
    //   path: '/password/reset',
    //   name: 'Password reset',
    //   component: () => import('./views/auth/PasswordReset.vue'),
    //   meta: {
    //     public: true,
    //     onlyWhenLoggedOut: true
    //   },
    // },
    {
      path: '/password/change/:token',
      name: 'Password change',
      component: () => import('./views/auth/PasswordResetChange.vue'),
      meta: {
        public: true,
        onlyWhenLoggedOut: true
      }
    },
    {
      path: '/appointment/confirm/',
      component: () => import('./views/AppointmentConfirm.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/appointment/confirmed',
      name: 'appointment-confirmation-result',
      component: () => import('./views/AppointmentConfirmed.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/t/:token/',
      beforeEnter: (x) => {
        location.href = process.env.VUE_APP_URL_BACKEND_API + '/public/appointment/confirm/?token=' + x.params.token
      },
      meta: {
        public: true
      }
    },
    {
      path: '/u/:token/',
      name: 'public-appointment-confirm',
      component: () => import('@/views/public/confirm/PublicAppointmentConfirmation.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/up/:token/',
      name: 'public-appointment-payment',
      component: () => import('@/views/public/confirm/PublicAppointmentConfirmation.vue'),
      meta: {
        public: true
      }
    },
    {
      path: '/w/:token',
      name: 'doctor-widget',
      component: () => import("@/views/public/PublicAppointmentView.vue"),
      meta: {
        public: true
      }
    },
    {
      path: '/p/:token',
      name: 'clinic-widget',
      component: () => import("@/views/public/PublicAppointmentView.vue"),
      meta: {
        public: true
      }
    },
    {
      path: '/e/:token',
      name: 'data-export',
      component: () => import("@/views/public/DataExportView.vue"),
      meta: {
        public: true
      }
    },
    {
      path: '*',
      name: 'page-not-found',
      component: () => import('./views/NotFound.vue'),
      meta: {
        public: true
      }
    }
  ],
})

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const token = store.state.auth.accessToken;
  const loggedIn = (token !== undefined && token !== null);

  if (!isPublic && !loggedIn) {
    window.location.href = window.location.origin; // vue router doesnt support external app redirect
    return 
  }

  if ((!to?.path.startsWith('/profile') && !to?.path.startsWith('/support') ) 
    && store.getters['auth/hasAccessToPaymentsOnly']) {
    if (store.getters['auth/isClinicEmployee']) {
      store.dispatch("clinic/updateDoctor", updateDoctor());
    }
    return next({
      path: '/profile',
    });
  }

  // Do not allow user to visit login page or register page if they are logged in
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  if (store.getters['auth/isClinicEmployee']) {
    let doctor = store.getters['clinic/doctor'];
    const ownData = doctor ? doctor.role_has_own_data : false;
    switch (to.path.split('/')[1]) {
      case "communication":
        doctor = updateDoctor(store.getters['auth/seesOthersComm'], ownData);
        break;
      case "statistics":
        doctor = updateDoctor(store.getters['auth/seesOthersStats'], ownData);
        break;
      case "timetable":
        doctor = updateDoctor(store.getters['auth/canMakeSchAndAppoToOthers'], ownData);
        break;
      case "timeline":
      case "support":
      case "reserve":
        doctor = updateDoctor(store.getters['auth/canMakeAppsToOthers'], ownData);
        break;
      case "settings":
        doctor = updateDoctor(store.getters['auth/accessToOthersSettings'], ownData);
        break;
      case "profile":
        doctor = updateDoctor(store.getters['auth/accessToOthersProfile'], ownData);
        break;
      case "patient":
        if (!ownData) {
          doctor = store.getters['clinic/firstDocWithData'];
        }
        break;
      default:
        break;
    }
    store.dispatch("clinic/updateDoctor", doctor);
  }

  next();
})

router.afterEach((to) => {
  const loggedIn = !!store.state.auth.accessToken;
  let type = 'Pozostałe';
  const pathParts = to.path.split('/')
  if (pathParts.length > 0) {
    switch (pathParts[1]) {
      case 'login':
        type = 'Logowanie'
        break;
      case 'register':
        type = 'Rejestracja'
        break;
      case 'p':
      case 'u':
      case 'w':
      case 't':
      case 'appointment':
      case 'password':
        type = 'Pozostałe'
        break;
      default:
        type = 'Panel użytkownika'
        break;
    }
  }

  Vue.gtm.trackEvent({
    event: 'virtualPageview',
    virtualPageURL: `/${pathParts.length > 0 ? pathParts[1] : pathParts[0]}`,
    pageType: type,
    userStatus: loggedIn ? 'Zalogowany' : 'Niezalogowany'
  });
});


export default router;