import ApiService from './api.service'

const TimetableService = {
    async getEvents(from_date, to_date, mode) {
        const requestData = {
            method: 'get',
            checkRole: true,
            params: {
                from_date: from_date,
                to_date: to_date,
                type: mode,
            },
            url: '/timetable/'
        }
        ApiService.setHeader()
        const events = await ApiService.customRequest(requestData)
        return events.data
    },
    async getDays(from_date, to_date, doctor) {
        const requestData = {
            method: 'get',
            checkRole: doctor ? undefined : true,
            params: {
                doctor: doctor ?  doctor.id : undefined,
                type: 1,
                from_date: from_date,
                to_date: to_date
            },
            url: '/timetable/'
        }
        ApiService.setHeader()
        const events = await ApiService.customRequest(requestData)
        return events.data
    },
    async getHours(date, doctor) {
        const requestData = {
            method: 'get',
            checkRole: doctor ? undefined : true,
            params: {
                doctor: doctor ?  doctor.id : undefined,
                type: 2,
                from_date: date,
                to_date: date
            },
            url: '/timetable/'
        }
        ApiService.setHeader()
        const events = await ApiService.customRequest(requestData)
        return events.data
    },
    async getSummary(from_date, to_date) {
        const requestData = {
            method: 'get',
            checkRole: true,
            params: {
                type: 3,
                from_date: from_date,
                to_date: to_date
            },
            url: '/timetable/'
        }
        ApiService.setHeader()
        const events = await ApiService.customRequest(requestData)
        return events.data
    },
    async getMultiEvents(from_date, to_date, doctors, type) {
        const requestData = {
            method: 'get',
            checkRole: true,
            params: {
                from_date: from_date,
                to_date: to_date,
                doctors: doctors,
                type: type,
            },
            url: '/timetable/'
        }
        ApiService.setHeader()
        const events = await ApiService.customRequest(requestData)
        return events.data
    },
}

export default TimetableService
