import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1BAAD6',
        secondary: '#7C7E8B',
        accent: '#F5FAFE',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        background: '#F6F7FB',
        dashboard_background: '#FFFFFF',
        disabled_item: '#7C7E8B',
        expressive_green: "#72DC59",
        disabled2: '#B6BAC5',
        lightgray_background: '#F9F9F9',
      }
    },
    options: {
      customProperties: true
    },
  },
  directives: {
    Ripple
  },
  icons: {
    iconfont: 'fa',
  }
})
