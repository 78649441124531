import axios from 'axios';
import store from "../store/index";
import router from "../router";

const ApiService = {
    init(baseURL) {
        axios.defaults.baseURL = baseURL;
        axios.interceptors.response.use(response => {
          return response;
        }, error => {
          if (error?.response?.status === 401 && router.currentRoute.path !== '/login') {
            store.dispatch('auth/logout', {autologout: true}).then(router.replace("/login"));
          }
          return Promise.reject(error);
        });
    },
    setHeader() {
        axios.defaults.headers.common["Authorization"] = `Token ${store.state.auth.accessToken}`;
    },
    removeHeader() {
        axios.defaults.headers.common = {}
    },
    customRequest(data) {
        if (data.checkRole) {
            let doctor = store.state.clinic.doctor;
            let role = store.state.auth.role;
            if (role === 2 && doctor === null) {
                throw 'Wybierz fizjoterapeutę.'
            } else if (doctor) {
                if (!data.params) {
                    data.params = {}
                }
                data.params.doctor = doctor.id
            }
        }

        if (data.params != undefined) {
            if (data.params.rowsPerPage != undefined) {
                data.params.page_size = data.params.rowsPerPage
                delete data.params.rowsPerPage
                delete data.params.totalItems
            }

            if (data.params.search != undefined) {
                let min = 3;
                if (data.params.min != undefined) {
                    min = data.params.min;
                }

                if (data.params.search.length < min) {
                    delete data.params.search
                }
            }

            if (data.params.sortBy != undefined) {
                let ops = ''
                if (data.params.sortBy == 'start_datetime') {
                    ops = 'start_date'
                } else if (data.params.sortBy == 'end_datetime') {
                    ops = 'end_date'
                } else {
                    ops = data.params.sortBy
                }

                data.params.ordering = data.params.descending ? ops : '-' + ops

                delete data.params.sortBy
                delete data.params.descending
            }
        }
        console.log('Request: ', data)
        return axios(data)
    }
}

export default ApiService
