import ApiService from "./api.service";

const NotesService = {
  async getNotes(options) {
    let tempOptions = JSON.parse(JSON.stringify(options));
    const requestData = {
      method: "get",
      params: tempOptions,
      url: "/notes/",
    };
    ApiService.setHeader();
    const notes = await ApiService.customRequest(requestData);
    return notes;
  },
  async getTermNotes(options) {
    let tempOptions = JSON.parse(JSON.stringify(options));
    const requestData = {
      method: "get",
      params: tempOptions,
      url: "/notes/term_notes/"
    };
    ApiService.setHeader();
    const notes = await ApiService.customRequest(requestData);
    return notes;
  },
  async getArchivedNotes(options) {
    let tempOptions = JSON.parse(JSON.stringify(options));
    const requestData = {
      method: "get",
      params: tempOptions,
      url: "/notes/?archived=1",
    };
    ApiService.setHeader();
    const notes = await ApiService.customRequest(requestData);
    return notes;
  },
  async addNote() {
    const requestData = {
      method: "get",
      url: "/notes/create/",
    };
    ApiService.setHeader();
    const createdNote = await ApiService.customRequest(requestData);
    return createdNote.data;
  },
  async toArchive(note) {
    const requestData = {
      method: "post",
      url: `/notes/archive/`,
      data: note,
    };
    ApiService.setHeader();
    const archiveNote = await ApiService.customRequest(requestData);
    return archiveNote.data;
  },
  async noteDone(note) {
    const requestData = {
      method: "post",
      url: `/notes/done/`,
      data: note,
    };
    ApiService.setHeader();
    const doneNote = await ApiService.customRequest(requestData);
    return doneNote.data;
  },
  async editNote(note) {
    const requestData = {
      method: "post",
      url: `/notes/${note.id}/`,
      data: note,
    };
    ApiService.setHeader();
    const updatedNote = await ApiService.customRequest(requestData);
    return updatedNote.data;
  },
  async deleteNote(note) {
    const requestData = {
      method: "delete",
      url: `/notes/${note.id}/`,
    };
    ApiService.setHeader();
    const deleteNote = await ApiService.customRequest(requestData);
    return deleteNote.data;
  },
  async shareNote(doctor, note) {
    const requestData = {
      method: "post",
      url: `/notes/share/${doctor}/`,
      data: note,
    };
    ApiService.setHeader();
    const updatedNote = await ApiService.customRequest(requestData);
    return updatedNote.data;
  },
  async unarchiveAllNote() {
    const requestData = {
      method: "get",
      url: "/notes/unarchive/",
    };
    ApiService.setHeader();
    const unarchiveAll = await ApiService.customRequest(requestData);
    return unarchiveAll.data;
  },
  async shareList() {
    const requestData = {
      method: "get",
      url: `/notes/share/list/`,
    };
    ApiService.setHeader();
    const updatedNote = await ApiService.customRequest(requestData);
    return updatedNote.data;
  },
  async getNearEndNote() {
    const requestData = {
      method: 'get',
      url: `/notes/near_end_time/`
    };
    ApiService.setHeader();
    const nearEndNote = await ApiService.customRequest(requestData);
    return nearEndNote.data;
  }
};

export default NotesService;
