import NotesService from '@/services/notes.service';

const state = {
  notesIds: {
    archive: null,
    done: null,
    deleted: null,
    ids: [],
  },
  termNotes: [],
  cards: [],
  cardsCount: 0,
  note: {},
  selectedNote: null
};

const getters = {
  getterNoteId: (state) => {
    return state.notesIds;
  },
  getTermNotes: (state) => {
    return state.termNotes;
  },
  getCards: (state) => {
    return state.cards;
  },
  getSelectedNote: (state) => {
    return state.selectedNote;
  },
  getNote: (state) => {
    return state.note;
  },
  getCardsCount: (state) => {
    return state.cardsCount;
  }
};
const mutations = {
  setNotesIds(state, value) {
    state.notesIds = value;
  },
  setTermNotes(state, value) {
    state.termNotes = [ ...value];
  },
  setTermNote(state, value) {
    state.note = { ...value};
  },
  setNoteProp(state, { prop, value }) {
    state.note = { ...state.note, [prop]: value };
  },
  addNoteSingleIds(state, value) {
    state.notesIds.ids.push(value);
  },
  addTermNote(state, value) {
    state.cards = [ ...state.termNotes, value];
  },
  addNotes(state, value) {
    const index = state.cards.findIndex(tn => tn.id === '');
    if (index === -1) {
      state.cards = [value, ...state.cards];
    }
  },
  removeCard(state, value) {
    const index = state.cards.findIndex(tn => tn.id === value.id);
    state.cards = [
      ...state.cards.slice(0, index === -1 ? 0 : index),
      ...state.cards.slice(index + 1)
    ];
  },
  setCards(state, value) {
    state.cards = [...value.results];
    state.cardsCount = value.count
  },
  setNoneCards(state) {
    state.cards = []
  },
  updateTermNotes(state, value) {
    const index = state.termNotes.findIndex(tn => tn.id === value.id);
    state.termNotes = [
      ...state.termNotes.slice(0, index === -1 ? 0 : index),
      value,
      ...state.termNotes.slice(index + 1)
    ];
  },
  updateCards(state, value) {
    const index = state.cards.findIndex(tn => tn.id === value.id);
    state.cards = [
      ...state.cards.slice(0, index === -1 ? 0 : index),
      value,
      ...state.cards.slice(index + 1)
    ];
  },
  removeNoteIds(state, value) {
    state.notesIds.ids = state.notesIds.ids.filter((e) => e !== value);
  },
  setArchive(state, value) {
    state.notesIds.archive = value;
  },
  setDone(state, value) {
    state.notesIds.done = value;
  },
  setDeleted(state, value) {
    state.notesIds.deleted = value;
  },
  setSelectedNote(state, noteId) {
    state.selectedNote = noteId;
  },
  reset(state) {
    state.notesIds.archive = null;
    state.notesIds.done = null;
    state.notesIds.deleted = null;
    state.notesIds.ids = [];
    state.cards = [];
    state.cardsCount = 0;
  },
};

const actions = {
  async selectNote(context, note) {
    context.commit("ui/setCalendarAppointmentNoteVisible", true, {root: true})
    context.commit('setSelectedNote', note.id);
  },
  async fetchTermNotes(context) {
    let startDate = context.rootGetters['ui/calendarStart'];
    let endDate = context.rootGetters['ui/calendarEnd'];
    return NotesService.getTermNotes({start_date: startDate, end_date: endDate})
    .then((x) => {
      return context.commit("setTermNotes", x.data.results);
    })
  },
  async fetchNotes(context, pagination) {
    return NotesService.getNotes(pagination)
      .then((reply) => {
        return context.commit('setCards', reply.data);
      })
  },
  async fetchArchiveNotes(context, pagination) {
    return NotesService.getArchivedNotes(pagination)
      .then((reply) => {
        return context.commit('setCards', reply.data);
      })
  },
  async setNotesIdsAction({ commit }, value) {
    commit("setNoteId", value);
  },
  async addNoteIdsAction({ commit }, value) {
    commit("addNoteSingleIds", value);
  },
  async removeNoteIdsAction({ commit }, value) {
    commit("removeNoteIds", value);
  },
  async setArchiveAction({ commit }, value) {
    commit("setArchive", value);
  },
  async setDoneAction({ commit }, value) {
    commit("setDone", value);
  },
  async setDeletedAction({ commit }, value) {
    commit("setDeleted", value);
  },
  async resetAction({ commit }) {
    commit("reset");
  },
};

export const notes = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default notes;
