import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./services/api.service";
import "@/styles/main.scss";
import VueTimers from "vue-timers";
import VueClipboard from "vue-clipboard2";
import lineClamp from "vue-line-clamp";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import VueGtm from "@gtm-support/vue2-gtm";
import Sortable, { AutoScroll } from "sortablejs/modular/sortable.core.esm.js";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.use(VueGtm, {
  id: "GTM-KHW6JC2",
  enabled: process.env.NODE_ENV !== "development",
});

Sortable.mount(new AutoScroll());

Vue.directive("mask", VueMaskDirective);

Vue.component(VueCropper);

Vue.use(lineClamp, {
  // plugin options
});

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.performance = process.env.NODE_ENV !== "production";

ApiService.init(process.env.VUE_APP_URL_BACKEND_API);

Vue.filter("formatDate", (dateString) => {
  try {
    return new Date(dateString).toISOString().split("T")[0];
  } catch (err) {
    return "-";
  }
});

Vue.use(VueTimers);

const ignoreWarnMessage =
  "The .native modifier for v-on is only valid on components but it was used on <div>.";
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
};

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "localhost",
          process.env.VUE_APP_URL_FRONTEND,
          /^\//,
        ],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.3,
  });
}

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
