import UIService from "../../services/ui.service";
import ModelLocalStorage from "../local/model";
import moment from "moment";

const NOTICE_UUID = "notice_uuid";
const CALENDAR_START = "calendar_start";
const CALENDAR_END = "calendar_end";
const CALENDAR_TIME_MARKER = "calendar_time_marker";
const CALENDAR_MODE = "calendar_mode";
const CALENDAR_APPOINTMENT_EXCLAMATION_MARK =
  "calendar_appointment_exclamation_mark";
const CALENDAR_APPOINTMENT_NOTE = "calendar_appointment_note";
const SHOW_HTML_EDITOR = "show_html_editor";
const NOTIFICATIONS_ONLY_RECEIVED = "notifications_only_received";
const NOTIFICATIONS_DISABLED = "notifications_disabled";
const PAYMENTS_TAB_VISIBLE = "payments_tab_visible";
const SYNC_P1 = "sync_p1";
const CALENDAR_DEFAULT_DURATION = "calendar_default_duration";
const VISIBLE_CARD_TYPES = "visible_card_types";
const MASS_NOTIFICATIONS_TAB_VISIBLE = "mass_notifications_tab_visible";
const RECOMMENDATIONS_VISIBLE = "visible_recommendations";
const FM_CARD_ENABLED = "fm_card_enabled";
const SHFS_COLLAPSED = "shfs_collapsed";
const PATIENT_FILES_GALLERY_CARD = "patient_files_gallery_card";
const SHOULD_REVALIDATE_FILES_GALLERY =
  "should_patient_files_gallery_revalidate";

const state = {
  actionMenuDrawerIsVisible: true,
  timetableActiveTab: 0,
  patientDetailsActiveTab: 0,
  calendarType: UIService.getCalendarType(),
  multiAppointments: undefined,
  settingsActiveTab: 0,
  noticeUUID: ModelLocalStorage.get(NOTICE_UUID, ""),
  noticeHeaderIsVisible: false,
  profileActiveTab: 0,
  calendarStart: ModelLocalStorage.get(
    CALENDAR_START,
    moment().format("YYYY-MM-DD")
  ),
  calendarEnd: ModelLocalStorage.get(CALENDAR_END, ""),
  calendarTimeMarkerIsVisible: ModelLocalStorage.get(
    CALENDAR_TIME_MARKER,
    true
  ),
  calendarMode: ModelLocalStorage.get(CALENDAR_MODE, 0),
  calendarAppointmentExclamationMarkIsVisible: ModelLocalStorage.get(
    CALENDAR_APPOINTMENT_EXCLAMATION_MARK,
    true
  ),
  calendarAppointmentNoteIsVisible: false,
  calendarDefaultDuration: ModelLocalStorage.get(CALENDAR_DEFAULT_DURATION, 30),
  scrollCalendar: "00:00",
  highlightComponentId: -1,
  highlightComponentColor: "",
  showHTMLEditor: ModelLocalStorage.get(SHOW_HTML_EDITOR, true),
  notificationsOnlyReceived: ModelLocalStorage.get(
    NOTIFICATIONS_ONLY_RECEIVED,
    false
  ),
  notificationsDisabled: ModelLocalStorage.get(NOTIFICATIONS_DISABLED, true),
  paymentsTabVisible: ModelLocalStorage.get(PAYMENTS_TAB_VISIBLE, false),
  syncP1: ModelLocalStorage.get(SYNC_P1, false),
  integrationsActiveTab: 0,
  visibleCardTypes: ModelLocalStorage.get(VISIBLE_CARD_TYPES, [0, 1, 2]),
  massNotificationsTabVisible: ModelLocalStorage.get(
    MASS_NOTIFICATIONS_TAB_VISIBLE,
    false
  ),
  recommendationsVisible: ModelLocalStorage.get(RECOMMENDATIONS_VISIBLE, false),
  fmCardEnabled: ModelLocalStorage.get(FM_CARD_ENABLED, false),
  shfsCollapsed: ModelLocalStorage.get(SHFS_COLLAPSED, true),
  patientFilesGalleryCollapsed: ModelLocalStorage.get(
    PATIENT_FILES_GALLERY_CARD,
    true
  ),
  shouldPatientFilesGalleryRevalidate: ModelLocalStorage.get(
    SHOULD_REVALIDATE_FILES_GALLERY,
    false
  ),
};

const getters = {
  actionMenuDrawerVisible: (state) => {
    return state.actionMenuDrawerIsVisible;
  },
  patientsPerPage: (state) => {
    return state.patientsPerPage;
  },
  patientDetailsActiveTab: (state) => {
    return state.patientDetailsActiveTab;
  },
  registratorsPerPage: (state) => {
    return state.registratorsPerPage;
  },
  doctorsPerPage: (state) => {
    return state.doctorsPerPage;
  },
  multiAppointments: (state) => {
    return state.multiAppointments;
  },
  calendarType: (state) => {
    return state.calendarType;
  },
  settingsActiveTab: (state) => {
    return state.settingsActiveTab;
  },
  noticeUUID: (state) => {
    return state.noticeUUID;
  },
  noticeHeaderVisible: (state) => {
    return state.noticeHeaderIsVisible;
  },
  profileActiveTab: (state) => {
    return state.profileActiveTab;
  },
  calendarStart: (state) => {
    switch (state.calendarType) {
      case "day":
        return state.calendarStart;
      case "clinic":
        return state.calendarStart;
      case "week":
        return moment(state.calendarStart, "YYYY-MM-DD")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
      case "month":
        return moment(state.calendarStart, "YYYY-MM-DD")
          .startOf("month")
          .format("YYYY-MM-DD");
      default:
        return state.calendarStart;
    }
  },
  calendarEnd: (state) => {
    let start = state.calendarStart;
    switch (state.calendarType) {
      case "day":
        return start;
      case "clinic":
        return start;
      case "week":
        return moment(start, "YYYY-MM-DD")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      case "month":
        return moment(start, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD");
      default:
        return start;
    }
  },
  calendarTimeMarkerVisible: (state) => {
    return state.calendarTimeMarkerIsVisible;
  },
  calendarMode: (state) => {
    return state.calendarMode;
  },
  calendarAppointmentExclamationMarkVisible: (state) => {
    return state.calendarAppointmentExclamationMarkIsVisible;
  },
  calendarAppointmentNoteVisible: (state) => {
    return state.calendarAppointmentNoteIsVisible;
  },
  calendarDefaultDuration: (state) => {
    return state.calendarDefaultDuration;
  },
  scrollCalendarTo: (state) => {
    return state.scrollCalendar;
  },
  highlightComponent: (state) => {
    return state.highlightComponentId;
  },
  highlightComponentColor: (state) => {
    return state.highlightComponentColor;
  },
  showHTMLEditor: (state) => {
    return state.showHTMLEditor;
  },
  notificationsOnlyReceived: (state) => {
    return state.notificationsOnlyReceived;
  },
  notificationsDisabled: (state) => {
    return state.notificationsDisabled;
  },
  paymentsTabVisible: (state) => {
    return state.paymentsTabVisible;
  },
  syncP1: (state) => {
    return state.syncP1;
  },
  integrationsActiveTab: (state) => {
    return state.integrationsActiveTab;
  },
  visibleCardTypes: (state) => {
    return state.visibleCardTypes;
  },
  massNotificationsTabVisible: (state) => {
    return state.massNotificationsTabVisible;
  },
  recommendationsVisible: (state) => {
    return state.recommendationsVisible;
  },
  fmCardEnabled: (state) => {
    return state.fmCardEnabled;
  },
  shfsCollapsed: (state) => {
    return state.shfsCollapsed;
  },
  patientFilesGalleryCollapsed: (state) => {
    return state.patientFilesGalleryCollapsed;
  },
  shouldPatientFilesGalleryRevalidate: (state) => {
    return state.shouldPatientFilesGalleryRevalidate;
  },
};

const mutations = {
  setActionMenuDrawerVisible(state, value) {
    state.actionMenuDrawerIsVisible = value;
  },
  setPatientsPerPage(state, value) {
    state.patientsPerPage = value;
  },
  setRegistratorsPerPage(state, value) {
    state.registratorsPerPage = value;
  },
  setDoctorsPerPage(state, value) {
    state.doctorsPerPage = value;
  },
  setTimetableActiveTab(state, value) {
    state.timetableActiveTab = value;
  },
  setPatientDetailsActiveTab(state, value) {
    state.patientDetailsActiveTab = value;
  },
  setCalendarType(state, value) {
    const before = state.calendarType;
    state.calendarType = value;
    UIService.setCalendarType(value);

    //If someone was on 'month' and switched on 'day' then
    //we should move him on 'today' date if there is current month.
    if (
      ((before != "day" &&
        before != "clinic" &&
        (value == "day" || value == "clinic")) ||
        (before == "month" && value == "week")) &&
      moment(state.calendarStart, "YYYY-MM-DD").isSame(new Date(), "month")
    ) {
      state.calendarStart = moment().format("YYYY-MM-DD");
    }
  },
  setMultiAppointments(state, value) {
    state.multiAppointments = value;
  },
  setSettingsActiveTab(state, value) {
    state.settingsActiveTab = value;
  },
  setNoticeUUID(state, value) {
    state.noticeUUID = value;
    ModelLocalStorage.save(NOTICE_UUID, value);
  },
  setNoticeHeaderVisible(state, value) {
    state.noticeHeaderIsVisible = value;
  },
  setProfileActiveTab(state, value) {
    state.profileActiveTab = value;
  },
  setCalendarStart(state, value) {
    state.calendarStart = value;
    ModelLocalStorage.save(CALENDAR_START, value);
  },
  setCalendarEnd(state, value) {
    state.calendarEnd = value;
    ModelLocalStorage.save(CALENDAR_END, value);
  },
  setCalendarTimeMarkerVisible(state, value) {
    state.calendarTimeMarkerIsVisible = value;
    ModelLocalStorage.save(CALENDAR_TIME_MARKER, value);
  },
  setCalendarMode(state, value) {
    state.calendarMode = value;
    ModelLocalStorage.save(CALENDAR_MODE, value);
  },
  setCalendarAppointmentExclamationMarkVisible(state, value) {
    state.calendarAppointmentExclamationMarkIsVisible = value;
    ModelLocalStorage.save(CALENDAR_APPOINTMENT_EXCLAMATION_MARK, value);
  },
  setCalendarAppointmentNoteVisible(state, value) {
    state.calendarAppointmentNoteIsVisible = value;
  },
  setCalendarDefaultDuration(state, value) {
    state.calendarDefaultDuration = value;
    ModelLocalStorage.save(CALENDAR_DEFAULT_DURATION, value);
  },
  setSrollCalendarTo(state, value) {
    state.scrollCalendar = value;
  },
  setHighlightComponent(state, value) {
    state.highlightComponentId = value;
  },
  setHighlightComponentColor(state, value) {
    state.highlightComponentColor = value;
  },
  setShowHTMLEditor(state, value) {
    state.showHTMLEditor = value;
    ModelLocalStorage.save(SHOW_HTML_EDITOR, value);
  },
  setNotificationsOnlyReceived(state, value) {
    state.notificationsOnlyReceived = value;
    ModelLocalStorage.save(NOTIFICATIONS_ONLY_RECEIVED, value);
  },
  setNotificationsDisabled(state, value) {
    state.notificationsDisabled = value;
    ModelLocalStorage.save(NOTIFICATIONS_DISABLED, value);
  },
  setPaymentsTabVisible(state, value) {
    state.paymentsTabVisible = value;
    ModelLocalStorage.save(PAYMENTS_TAB_VISIBLE, value);
  },
  setSyncP1(state, value) {
    state.syncP1 = value;
    ModelLocalStorage.save(SYNC_P1, value);
  },
  setIntegrationsActiveTab(state, value) {
    state.integrationsActiveTab = value;
  },
  setVisibleCardTypes(state, value) {
    state.visibleCardTypes = value;
    ModelLocalStorage.save(VISIBLE_CARD_TYPES, value);
  },
  setMassNotificationsTabVisible(state, value) {
    state.massNotificationsTabVisible = value;
    ModelLocalStorage.save(MASS_NOTIFICATIONS_TAB_VISIBLE, value);
  },
  setRecommendationsVisible(state, value) {
    state.recommendationsVisible = value;
    ModelLocalStorage.save(RECOMMENDATIONS_VISIBLE, value);
  },
  setFmCardEnabled(state, value) {
    state.fmCardEnabled = value;
    ModelLocalStorage.save(FM_CARD_ENABLED, value);
  },
  setShfsCollapsed(state, value) {
    state.shfsCollapsed = value;
    ModelLocalStorage.save(SHFS_COLLAPSED, value);
  },
  setPatientFilesGalleryCollapsed(state, value) {
    state.patientFilesGalleryCollapsed = value;
    ModelLocalStorage.save(PATIENT_FILES_GALLERY_CARD, value);
  },
  setShouldPatientFilesGalleryRevalidate(state, value) {
    state.shouldPatientFilesGalleryRevalidate = value;
    ModelLocalStorage.save(SHOULD_REVALIDATE_FILES_GALLERY, value);
  },
};

const actions = {
  async showActionMenuDrawer({ commit }, value) {
    commit("setActionMenuDrawerVisible", value);
  },
  async setPatientsPerPage({ commit }, value) {
    commit("setPatientsPerPage", value);
  },
  async setRegistratorsPerPage({ commit }, value) {
    commit("setRegistratorsPerPage", value);
  },
  async setDoctorsPerPage({ commit }, value) {
    commit("setDoctorsPerPage", value);
  },
  async updateTimetableActiveTab({ commit }, value) {
    commit("setTimetableActiveTab", value);
  },
  async updatePatientDetailsActiveTab({ commit }, value) {
    commit("setPatientDetailsActiveTab", value);
  },
  async updateCalendarType({ commit }, value) {
    commit("setCalendarType", value);
  },
  async updateMultiAppointments({ commit }, value) {
    commit("setMultiAppointments", value);
  },
  async reset({ commit }) {
    commit("setMultiAppointments", []);
    commit("setCalendarStart", moment().format("YYYY-MM-DD"));
    commit("setCalendarEnd", "");
    commit("setSettingsActiveTab", 0);
    commit("setProfileActiveTab", 0);
    commit("setPaymentsTabVisible", false);
    commit("setMassNotificationsTabVisible", false);
    //Reset lists:
    ModelLocalStorage.remove("nl_paginator");
    ModelLocalStorage.remove("apl_paginator");
    ModelLocalStorage.remove("dl_paginator");
    ModelLocalStorage.remove("el_paginator");
    ModelLocalStorage.remove("al_paginator");
    ModelLocalStorage.remove("pl_paginator");
    ModelLocalStorage.remove("rl_paginator");
    ModelLocalStorage.remove("sl_paginator");
    ModelLocalStorage.remove("ral_paginator");
  },
  async updateSettingsActiveTab({ commit }, value) {
    commit("setSettingsActiveTab", value);
  },
  async updateNoticeUUID({ commit }, value) {
    commit("setNoticeUUID", value);
  },
  async updateNoticeHeaderVisible({ commit }, value) {
    commit("setNoticeHeaderVisible", value);
  },
  async updateProfileActiveTab({ commit }, value) {
    commit("setProfileActiveTab", value);
  },
  async updateCalendarStart({ commit }, value) {
    commit("setCalendarStart", value);
  },
  async updateCalendarEnd({ commit }, value) {
    commit("setCalendarEnd", value);
  },
  async updateCalendarTimeMarkerVisible({ commit }, value) {
    commit("setCalendarTimeMarkerVisible", value);
  },
  async updateCalendarMode({ commit }, value) {
    commit("setCalendarMode", value);
  },
  async updateCalendarAppointmentExclamationMarkVisible({ commit }, value) {
    commit("setCalendarAppointmentExclamationMarkVisible", value);
  },
  async updateCalendarData(
    { commit },
    { marker, exclamationMark, defaultDuration }
  ) {
    commit("setCalendarTimeMarkerVisible", marker);
    commit("setCalendarAppointmentExclamationMarkVisible", exclamationMark);
    commit("setCalendarDefaultDuration", defaultDuration);
  },
  async updateCalendarAppointmentNoteVisible({ commit }, value) {
    commit("setCalendarAppointmentNoteVisible", value);
  },
  async goToDay({ commit }, value) {
    commit("setCalendarType", "day");
    commit("setCalendarStart", value);
    commit("setCalendarEnd", value);
  },
  async srollCalendarToTimeDelegates({ commit }, value) {
    commit("setSrollCalendarTo", value.time);
    commit("setHighlightComponent", value.id);
    commit("setHighlightComponentColor", value.color);
  },
  async srollCalendarToTimeReset({ commit }) {
    commit("setSrollCalendarTo", "00:00");
    commit("setHighlightComponent", -1);
    commit("setHighlightComponentColor", "");
  },
  async highlightCalendarDelegateId({ commit }, value) {
    commit("setHighlightComponent", value);
  },
  async updateShowHTMLEditor({ commit }, value) {
    commit("setShowHTMLEditor", value);
  },
  async updateNotificationsOnlyReceived({ commit }, value) {
    commit("setNotificationsOnlyReceived", value);
  },
  async updateNotificationsDisabled({ commit }, value) {
    commit("setNotificationsDisabled", value);
  },
  async updatePaymentsTabVisible({ commit }, value) {
    commit("setPaymentsTabVisible", value);
  },
  async updateSyncP1({ commit }, value) {
    commit("setSyncP1", value);
  },
  async updateIntegrationsActiveTab({ commit }, value) {
    commit("setIntegrationsActiveTab", value);
  },
  async updateVisibleCardTypes({ commit }, value) {
    commit("setVisibleCardTypes", value);
  },
  async updateMassNotificationsTabVisible({ commit }, value) {
    commit("setMassNotificationsTabVisible", value);
  },
  async updateRecommendationsVisible({ commit }, value) {
    commit("setRecommendationsVisible", value);
  },
  async updateFmCardEnabled({ commit }, value) {
    commit("setFmCardEnabled", value);
  },
  async updateShfsCollapsed({ commit }, value) {
    commit("setShfsCollapsed", value);
  },
  async updatePatientFilesCardCollapsed({ commit }, value) {
    commit("setPatientFilesGalleryCollapsed", value);
  },
  async updateShouldPatientFilesGalleryRevalidate({ commit }, value) {
    commit("setShouldPatientFilesGalleryRevalidate", value);
  },
};

export const ui = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default ui;
