import Vue from "vue";

const NOT_MODIFIED = 0;
const MODIFIED = 1;
const BEFORE_SYNCING = 2;
const SYNCING = 3;
const SYNCED = 4;

const state = {
  map: {}
};

const getters = {
  getStatus: (state) => (id) => {
    if (state.map[id] !== undefined) {
      return state.map[id];
    } else {
      return NOT_MODIFIED;
    }
  },
  statusChange: (state) => {
    return state.map;
  }
};

const mutations = {
  setStatus: (state, obj) => {
    Vue.set(state.map, obj.id, obj.value);
  },
  reset: (state, obj) => {
    Object.keys(state.map).forEach((key) => {
      Vue.set(state.map, key, obj);
    });
  }
};

const actions = {
  async notModified({ commit }, id) {
    commit("setStatus", { id: id, value: NOT_MODIFIED });
  },
  async modified({ commit }, id) {
    commit("setStatus", { id: id, value: MODIFIED });
  },
  async syncing({ commit }, id) {
    commit("setStatus", { id: id, value: SYNCING });
  },
  async synced({ commit }, id) {
    commit("setStatus", { id: id, value: SYNCED });
  },
  async beforeSyncing({ commit }, id) {
    commit("setStatus", { id: id, value: BEFORE_SYNCING });
  },
  async reset({ commit }) {
    commit("reset", NOT_MODIFIED);
  }
};

export const autosave = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};

export default autosave;
