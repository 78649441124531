import { UserService } from '@/services/user.service'
import ModelLocalStorage from "@/store/local/model";
import { ONLY_PAYMENTS_ACCESS, FULL_ACCESS } from '@/constants/user';

const ROLE_KEY = 'role'
const FIRST_NAME_KEY = 'first_name'
const LAST_NAME_KEY = 'last_name'
const CLINIC_EMPLOYEE_KEY = 'clinic_employee'
const CLINIC_OWNER_KEY = 'clinic_owner'
const PHOTO_KEY = 'photo'
const PERMISSIONS_KEY = "permissions";
const JOB_TYPE_KEY = "job_type";
const ACCESS_TYPE_KEY = "access_type";
const TOKEN_KEY = 'access_token';


const state = {
    processing: false,
    accessToken: ModelLocalStorage.get(TOKEN_KEY, null),
    error: null,
    role: ModelLocalStorage.get(ROLE_KEY, null),
    first_name: ModelLocalStorage.get(FIRST_NAME_KEY, null),
    last_name: ModelLocalStorage.get(LAST_NAME_KEY, null),
    photo: ModelLocalStorage.get(PHOTO_KEY, null),
    clinicEmployee: ModelLocalStorage.get(CLINIC_EMPLOYEE_KEY, false),
    clinicOwner: ModelLocalStorage.get(CLINIC_OWNER_KEY, false),
    autoLoggedOut: false,
    jobType: ModelLocalStorage.get(JOB_TYPE_KEY, null),
    accessType: ModelLocalStorage.get(ACCESS_TYPE_KEY, null),
    permissions: ModelLocalStorage.get(PERMISSIONS_KEY, null),
}

const getters = {
    loggedIn: (state) => {
        return state.accessToken ? true : false
    },
    isDoctor: (state) => {
        return state.role == 1
    },
    isRegistrator: (state) => {
        return state.role == 2
    },
    isError: (state) => {
        return state.error != null
    },
    fullName: (state) => {
        return state.first_name + ' ' + state.last_name
    },
    userPhoto: (state) => {
        return state.photo;
    },
    isClinicOwner: state => {
        return state.clinicOwner;
    },
    isClinicEmployee: state => {
        return state.clinicEmployee;
    },
    isAutoLoggedOut: state => {
        return state.autoLoggedOut;
    },
    jobType: state => {
        return state.jobType;
    },
    permissions: state => {
        return state.permissions;
    },
    hasAccessToStats: state => {
        return state.role == 1 && (!state.clinicEmployee || (state.permissions && state.permissions?.stats?.has_access));
    },
    seesOthersStats: state => {
        return state.clinicEmployee && (state.permissions && state.permissions?.stats?.sees_others);
    },
    hasOwnData: state => {
        return state.role != 2 && state.jobType !== "registrator" && (!state.clinicEmployee || (state.permissions && state.permissions?.department.has_own_data));
    },
    canMakeAppsToOthers: state => {
        return state.role == 2 || (state.clinicEmployee && state.permissions && state.permissions?.schedules?.can_make_apps_to_others);
    },
    canMakeSchAndAppoToOthers: state => {
        return state.role == 2 || (state.clinicEmployee && state.permissions && state.permissions?.schedules?.can_make_sch_and_appo_to_others);
    },
    seesOthersComm: state => {
        return state.role == 2 || (state.clinicEmployee && state.permissions && state.permissions?.notifications?.sees_others_comm);
    },
    rcvsOthersAnn: state => {
        return state.role == 2 || (state.clinicEmployee && (state.permissions && state.permissions?.notifications?.rcvs_others_ann));
    },
    accessToOthersFiles: state => {
        return state.role != 2 && (!state.clinicEmployee || (state.permissions && state.permissions?.files?.access_to_others_files));
    },
    accessToOthersSettings: state => {
        return state.role != 2 && (!state.clinicEmployee || (state.permissions && state.permissions?.settings?.access_to_others_settings));
    },
    accessToOthersProfile: state => {
        return state.role != 2 && (!state.clinicEmployee || (state.permissions && state.permissions?.settings?.access_to_others_profile));
    },
    accessToRoles: state => {
        return state.role != 2 && (state.clinicEmployee && (state.permissions && state.permissions?.settings?.access_to_roles));
    },
    accessToModifyOffices: state => {
        return state.role != 2 && (!state.clinicEmployee || (state.permissions && state.permissions?.settings?.access_to_modify_offices));
    },
    seesOthersCards: state => {
        return state.role == 1 && state.jobType !== "registrator" && state.clinicEmployee && state.permissions && state.permissions?.patients?.sees_others_cards;
    },
    hasAccessToPatientPayments: state => {
        return !state.clinicEmployee || (state.permissions && state.permissions?.patient_payments?.has_access);
    },
    canCreatePatientPaymentsAccount: state => {
        return !state.clinicEmployee || (state.permissions && state.permissions?.patient_payments?.can_create_own_account);
    },
    seesOthersPatientPayments: state => {
        return !state.clinicEmployee || (state.permissions && state.permissions?.patient_payments?.sees_others);
    },
    canRefundPatientPayments: state => {
        return !state.clinicEmployee || (state.permissions && state.permissions?.patient_payments?.can_refund);
    },
    canManagePatientPayments: state => {
        return !state.clinicEmployee || (state.permissions && state.permissions?.patient_payments?.can_manage);
    },
    hasAccessToPaymentsOnly: state => {
        return state.accessType === ONLY_PAYMENTS_ACCESS;
    },
    hasFullAccess: state => {
        return state.accessType === FULL_ACCESS;
    }
}

const internalReset = (commit) => {
    commit('setFirstName', null);
    commit('setLastName', null);
    commit('setPhoto', null);
    commit('setRole', null);
    commit('setPermissions', null);
    commit('setJobType', null);
    commit('setClinicEmployee', false);
    commit('setClinicOwner', false);
    commit('setPermissions', null);
    commit('setAccessType', null);
    commit('setToken', null);
}

const actions = {
    async updateLoginData(
        { commit }, 
        { 
            token, 
            role, 
            first_name,
            last_name, 
            clinicOwner,
            clinicEmployee, 
            permissions, 
            photo, 
            job_type,
            access_type,
        }) {
        return new Promise((resolve, reject) => {
            commit('setRole', role);
            commit('setToken', token);
            commit('setFirstName', first_name);
            commit('setLastName', last_name);
            commit('setPhoto', photo);
            commit('setClinicOwner', clinicOwner);
            commit('setClinicEmployee', clinicEmployee);
            commit('setPermissions', permissions);
            commit('setJobType', job_type);
            commit('setAccessType', access_type);
            resolve(token);
        })
    },
    async updateName({ commit }, { first_name, last_name, photo }) {
        commit('setFirstName', first_name);
        commit('setLastName', last_name);
        if (photo !== undefined) {
            commit('setPhoto', photo);
        }
    },
    async updateAccessType({ commit }, value) {
        commit('setAccessType', value);
    },
    async updateAutoLoggedOut({ commit }, { autologgedout }) {
        commit('setAutoLoggedOut', autologgedout);
    },
    async logout({ commit }, { autologout, logoutFromAllDevices }) {
        await UserService.logout(logoutFromAllDevices);
        if (autologout === true) {
            commit('setAutoLoggedOut', true);
        }
        internalReset(commit);
    },
    async reset({ commit }) {
        internalReset(commit);
    },
}

const mutations = {
    request(state) {
        state.processing = true;
        state.error = null
    },
    error(state, data) {
        state.processing = false
        state.error = data
    },
    success(state) {
        state.processing = false
        state.error = null
    },
    logoutSuccess(state) {
        state.processing = false;
        state.error = null;
    },
    setRole(state, role) {
        state.role = role
        ModelLocalStorage.save(ROLE_KEY, role);
    },
    setToken(state, token) {
        state.accessToken = token;
        ModelLocalStorage.save(TOKEN_KEY, token)
    },
    setFirstName(state, first_name) {
        state.first_name = first_name
        ModelLocalStorage.save(FIRST_NAME_KEY, first_name);
    },
    setLastName(state, last_name) {
        state.last_name = last_name
        ModelLocalStorage.save(LAST_NAME_KEY, last_name);
    },
    setPhoto(state, photo) {
        state.photo = photo;
        ModelLocalStorage.save(PHOTO_KEY, photo);
    },
    setClinicOwner(state, clinicOwner) {
        state.clinicOwner = clinicOwner;
        ModelLocalStorage.save(CLINIC_OWNER_KEY, clinicOwner);
    },
    setClinicEmployee(state, clinicEmployee) {
        state.clinicEmployee = clinicEmployee;
        ModelLocalStorage.save(CLINIC_EMPLOYEE_KEY, clinicEmployee);
    },
    setAutoLoggedOut(state, autologgedout) {
        state.autoLoggedOut = autologgedout;
    },
    setJobType(state, jobType) {
        state.jobType = jobType;
        ModelLocalStorage.save(JOB_TYPE_KEY, jobType);
    },
    setPermissions(state, permissions) {
        state.permissions = permissions;
        ModelLocalStorage.save(PERMISSIONS_KEY, permissions);
    },
    setAccessType(state, accessType) {
        state.accessType = accessType;
        ModelLocalStorage.save(ACCESS_TYPE_KEY, accessType);
    },
}

export const auth = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default auth
