import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import ui from './modules/ui';
import autosave from "./modules/autosave";
import clinic from "./modules/clinic";
import stats from "./modules/stats";
import notes from "./modules/notes";
import editor from "./modules/editor";
import calendar from './modules/calendar';
import notifications from './modules/notifications';

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    ui,
    calendar,
    autosave,
    clinic,
    stats,
    notes,
    editor,
    notifications
  },
  strict: debug,
})
