import ApiService from './api.service';
import store from "../store/index";


const UserService = {
    register: async function (data) {
        const requestData = {
            method: 'post',
            url: "/register/",
            data: {
                email: data.email,
                password: data.password,
                phone: data.phone,
                last_name: data.lastName,
                first_name: data.firstName,
                accept_rules: data.acceptRules,
                job_type: data.jobType,
                job: data.job
            }
        }
        ApiService.removeHeader();
        const response = await ApiService.customRequest(requestData)
        return response.data
    },

    passwordReset: async function (email) {
        const requestData = {
            method: 'post',
            url: "/password/public/reset/",
            data: {
                'email': email,
            }
        }
        ApiService.removeHeader();
        const response = await ApiService.customRequest(requestData)
        return response.data
    },

    passwordResetChange: async function (token, password) {
        const requestData = {
            method: 'post',
            url: "/password/public/confirm/",
            data: {
                token: token,
                password: password,
            }
        }
        ApiService.removeHeader();
        const response = await ApiService.customRequest(requestData)
        return response.data
    },

    async passwordChange(old_password, new_password) {
        const requestData = {
            method: 'post',
            url: `/password/change/`,
            data: {
                old_password: old_password,
                new_password: new_password
            }
        }
        ApiService.setHeader()
        const response = await ApiService.customRequest(requestData)
        return response.data
    },

    login: async function (email, password) {
        const requestData = {
            method: 'post',
            url: "/login/",
            data: {
                email: email,
                password: password
            },
        }
        ApiService.removeHeader();
        const response = await ApiService.customRequest(requestData);
        return response.data
    },

    logout: async function (logoutFromAllDevices=false) {
        if (logoutFromAllDevices) {
            try {
                const requestData = {
                    method: 'get',
                    url: "/logout/",
                }
                await ApiService.customRequest(requestData);
            } catch {
                console.log("Bad token");
            }
        }

        store.dispatch("clinic/reset");
        store.dispatch("ui/reset");
        store.dispatch("autosave/reset");
        store.dispatch("notifications/reset");
        store.dispatch("notes/resetAction");
        store.dispatch("auth/reset");

        ApiService.removeHeader()
    }
}

export default { UserService }

export { UserService }
