const state = {
    recvPatientsMsgCount: {},
    recvMsgCount: {}
}

const handleDoctorId = (state, doctorId) => {
    let docId = doctorId;
    if (!docId) {
        docId = Object.keys(state.recvPatientsMsgCount)?.[0];
    }
    return docId || 0;
}

const getters = {
    getRecvPatientsMsgCount: (state) => {
        return state.recvPatientsMsgCount;
    },
    getRecvMsgCount: (state) => (doctorId) => {
        return state.recvMsgCount[handleDoctorId(state, doctorId)];
    },
    hasNewMsgFromPatients: (state) => (doctorId) => {
        return (state.recvMsgCount[handleDoctorId(state, doctorId)]) > 0;
    },
    hasNewMsgFromPatient: (state) => (doctorId, patientId) => {
        return state.recvPatientsMsgCount[handleDoctorId(state, doctorId)]?.[patientId];
    }
}

const mutations = {
    setRecvPatientsMsgCount(state, value = {}) {
        const recvPatientsMsgCountObject = {};
        const recvMsgCount = {};
        const keys = Object.keys(value);
        for (let index = 0; index < keys.length; index++) {
            const doctorKey = keys[index];
            const doctorMessages = value[doctorKey];
            recvPatientsMsgCountObject[doctorKey] = {};
            recvMsgCount[doctorKey] = 0;
            for (let index = 0; index < doctorMessages.length; index++) {
                const recvPatientMsgCount = doctorMessages[index];
                recvPatientsMsgCountObject[doctorKey][recvPatientMsgCount.patient] = recvPatientMsgCount.count;
                recvMsgCount[doctorKey] += recvPatientMsgCount.count;
            }
        }
        
        state.recvPatientsMsgCount = recvPatientsMsgCountObject;
        state.recvMsgCount = recvMsgCount;
    }
}

const actions = {
    async updateRecvPatientsMsgCount({ commit }, value) {
        commit("setRecvPatientsMsgCount", value);
    },
    async reset({commit}) {
        commit("setRecvPatientsMsgCount", []);
    }
}

export const notifications = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  
  export default notifications;