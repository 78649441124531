const moment = require("moment");
import ModelLocalStorage from "../local/model"

const VISIBLE_STATS = "visible_stats";

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const calculateChunks = (str_date, period) => {
    let start = null;
    let stop = null;
    let chunks = [];
    moment.locale('pl');
    switch (period) {
        case "week":
            start = moment(str_date, 'YYYY-MM-DD').startOf('isoWeek');
            chunks.push({
                value: Number(start.format("D")),
                format: capitalize(start.format('dddd - YYYY-MM-DD')),
                shortFormat: capitalize(start.format('ddd'))
            });
            for (let index = 0; index < 6; index++) {
                start.add(1, 'days')
                chunks.push({
                    value: Number(start.format("D")),
                    format: capitalize(start.format('dddd - YYYY-MM-DD')),
                    shortFormat: capitalize(start.format('ddd'))
                });
            }
            break;
        case "month":
            start = moment(str_date, 'YYYY-MM-DD').startOf('month');
            stop = moment(str_date, 'YYYY-MM-DD').endOf('month');
            while (start < stop) {
                let startWeek = moment(start).startOf('isoWeek');
                if (startWeek < start) {
                    startWeek = start;
                }
                let endWeek = moment(startWeek).endOf('isoWeek');
                if (endWeek > stop) {
                    endWeek = stop;
                }
                chunks.push({
                    value: Number(startWeek.format('W')),
                    format: `#${startWeek.format('WW (YYYY-MM-DD')} - ${endWeek.format('YYYY-MM-DD')})`,
                    shortFormat: `#${startWeek.format('WW')}`
                });
                start.add(1, 'week');
                start.isoWeekday(1);
            }
            break;
        case "year":
            start = moment(str_date, 'YYYY-MM-DD').startOf('year');
            chunks.push({
                value: Number(start.format('M')),
                format: capitalize(start.format('MMMM')),
                shortFormat: capitalize(start.format('MMM'))
            });
            for (let index = 0; index < 11; index++) {
                start.add(1, 'month');
                chunks.push({
                    value: Number(start.format('M')),
                    format: capitalize(start.format('MMMM')),
                    shortFormat: capitalize(start.format('MMM'))
                });
            }
            break;
    }
    // console.log("C", chunks);
    return chunks;
}

const state = {
    date: moment().format('YYYY-MM-DD'),
    period: "week",
    chunks: calculateChunks(moment().format('YYYY-MM-DD'), 'week'),
    office: 0,
    visibleStats: ModelLocalStorage.get(VISIBLE_STATS, JSON.stringify(['appointment', 'income', 'patient', 'service'])),
}

const getters = {
    getDate: (state) => {
        return state.date;
    },
    getPeriod: (state) => {
        return state.period;
    },
    getChunks: (state) => {
        return state.chunks;
    },
    getOffice: (state) => {
        return state.office;
    },
    getVisibleStats: (state) => {
        return JSON.parse(state.visibleStats);
    }
}

const mutations = {
    setDate(state, value) {
        state.date = value;
        state.chunks = calculateChunks(value, state.period);
    },
    setPeriod(state, value) {
        state.period = value;
        state.chunks = calculateChunks(state.date, value);
    },
    setChunks(state, value) {
        state.chunks = value;
    },
    setOffice(state, value) {
        state.office = value;
    },
    setVisibleStats(state, value) {
        ModelLocalStorage.save(VISIBLE_STATS, JSON.stringify(value))
        state.visibleStats = JSON.stringify(value);
    }
}

const actions = {
    async updateDate({ commit }, value) {
        commit('setDate', value);
    },
    async updatePeriod({ commit }, value) {
        commit('setPeriod', value);
    },
    async updateOffice({ commit }, value) {
        commit('setOffice', value);
    },
    async updateVisibleStats({ commit }, value) {
        commit('setVisibleStats', value);
    }
}

export const stats = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

export default stats;