const CALENDAR_TYPE = "calendar_type"
const RESULTS_PER_PAGE = "results_per_page"

const UIService = {
    getCalendarType() {
        var type = localStorage.getItem(CALENDAR_TYPE)
        if (type == null)
            return "day"
        return type
    },
    setCalendarType(value) {
        return localStorage.setItem(CALENDAR_TYPE, value)
    },
}

export default UIService