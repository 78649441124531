import ModelLocalStorage from "../local/model";
import { sha256 } from "js-sha256";
import Vue from "vue";
import _ from "lodash";

const DOCTOR_KEY = "doctor_v1";
const SELECTED_EMPLOYEES_KEY = "selected_employees";
const SELECTED_OFFICES_KEY = "selected_offices";
const NUMBER_DOCTOR_VISIBLE = "number_doctor_visible";

const state = {
  doctor: null,
  doctors: [],
  doctorsMap: {},
  doctorAvatars: {},
  selectedEmployees: [],
  offices: [],
  selectedOffices: [],
  unselectedRooms: [],
  selectedTabOffice: {},
  numberDoctorIsVisible: ModelLocalStorage.get(NUMBER_DOCTOR_VISIBLE, 4),
};

const getters = {
  doctor: (state) => {
    return state.doctor;
  },
  doctors: (state) => {
    return state.doctors;
  },
  doctorAvatars: (state) => {
    return state.doctorAvatars;
  },
  selectedEmployees: (state) => {
    return state.selectedEmployees;
  },
  isSelectedEmployee: (state) => (employee) => {
    for (let index = 0; index < state.selectedEmployees.length; index++) {
      const element = state.selectedEmployees[index];
      if (element.id == employee.id) {
        return true;
      }
    }
    return false;
  },
  doctorById: (state) => (id) => {
    return state.doctorsMap[parseInt(id)];
  },
  doctorAvatarByPath: (state) => (path) => {
    return state.doctorAvatars[path];
  },
  offices: (state) => {
    return state.offices;
  },
  selectedOffices: (state) => {
    return state.selectedOffices;
  },
  isSelectedOffice: (state) => (office) => {
    for (let index = 0; index < state.selectedOffices.length; index++) {
      const element = state.selectedOffices[index];
      if (element.id == office.id) {
        return true;
      }
    }
    return false;
  },
  selectedTabOffice: (state) => (key) => {
    return state.selectedTabOffice[key];
  },
  officesMap: (state) => {
    return state.offices.reduce((map, obj) => ((map[obj.id] = obj), map), {});
  },
  me: (state) => {
    const foundIndex = _.findIndex(state.doctors, { me: true });
    if (foundIndex == -1) {
      return null;
    } else {
      return state.doctors[foundIndex];
    }
  },
  firstDocWithData: (state) => {
    const foundIndex = _.findIndex(state.doctors, {
      role_has_own_data: true,
      active: true,
    });
    if (foundIndex == -1) {
      return null;
    } else {
      return state.doctors[foundIndex];
    }
  },
  numberDoctorVisible: (state) => {
    return state.numberDoctorIsVisible;
  },
  unselectedRooms: (state) => {
    return state.unselectedRooms;
  },
};

const hashedLocStorageArray = (key, array, property) => {
  if (array && array.length > 0) {
    let hashedElements = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (!element) {
        continue;
      }
      hashedElements.push(sha256(element[property]?.toString()));
    }
    ModelLocalStorage.save(key, hashedElements);
  } else {
    ModelLocalStorage.save(key, []);
  }
};

const mutations = {
  setDoctor: (state, value) => {
    state.doctor = value;

    if (value) {
      ModelLocalStorage.save(DOCTOR_KEY, sha256(value.id.toString()));
    } else {
      ModelLocalStorage.save(DOCTOR_KEY, "");
    }
  },
  setDoctors: (state, value) => {
    state.doctors = value;
    state.doctorsMap = state.doctors.reduce(
      (obj, item) => ((obj[item.id] = item), obj),
      {}
    );
  },
  setSelectedEmployees: (state, value) => {
    state.selectedEmployees = value;
    hashedLocStorageArray(
      SELECTED_EMPLOYEES_KEY,
      state.selectedEmployees,
      "id"
    );
  },
  resetSelectedEmployees: (state) => {
    state.selectedEmployees = [];
  },
  addSelectedEmployee: (state, value) => {
    state.selectedEmployees.push(value);
    hashedLocStorageArray(
      SELECTED_EMPLOYEES_KEY,
      state.selectedEmployees,
      "id"
    );
  },
  removeSelectedEmployee: (state, value) => {
    for (let index = 0; index < state.selectedEmployees.length; index++) {
      const element = state.selectedEmployees[index];
      if (element.id == value.id) {
        state.selectedEmployees.splice(index, 1);
        break;
      }
    }
    hashedLocStorageArray(
      SELECTED_EMPLOYEES_KEY,
      state.selectedEmployees,
      "id"
    );
  },
  updateSelectedEmployee(state, value) {
    for (let index = 0; index < state.selectedEmployees.length; index++) {
      const element = state.selectedEmployees[index];
      if (element.id == value.id) {
        state.selectedEmployees[index].color = value.color;
        break;
      }
    }
    hashedLocStorageArray(
      SELECTED_EMPLOYEES_KEY,
      state.selectedEmployees,
      "id"
    );
  },
  setOffices: (state, value) => {
    state.offices = value;
    // RESTORE SELECTED OFFICES!
    const hashedOfficesIds = ModelLocalStorage.get(SELECTED_OFFICES_KEY, []);
    if (hashedOfficesIds.length > 0) {
      let newSelectedOffices = [];
      for (
        let hashIndex = 0;
        hashIndex < hashedOfficesIds.length;
        hashIndex++
      ) {
        const hashedOfficeId = hashedOfficesIds[hashIndex];
        for (let index = 0; index < state.offices.length; index++) {
          const office = state.offices[index];
          if (hashedOfficeId == sha256(office.id.toString())) {
            newSelectedOffices.push(office);
            break;
          }
        }
      }
      if (newSelectedOffices.length == 0) {
        newSelectedOffices = JSON.parse(JSON.stringify(state.offices));
      }
      state.selectedOffices = newSelectedOffices;
    } else {
      state.selectedOffices = JSON.parse(JSON.stringify(state.offices));
    }
  },
  setSelectedOffices: (state, value) => {
    state.selectedOffices = value;
    hashedLocStorageArray(SELECTED_OFFICES_KEY, state.selectedOffices, "id");
  },
  addSelectedOffice: (state, value) => {
    state.selectedOffices.push(value);
    hashedLocStorageArray(SELECTED_OFFICES_KEY, state.selectedOffices, "id");
  },
  removeSelectedOffice: (state, value) => {
    for (let index = 0; index < state.selectedOffices.length; index++) {
      const element = state.selectedOffices[index];
      if (element.id == value.id) {
        state.selectedOffices.splice(index, 1);
        break;
      }
    }
    hashedLocStorageArray(SELECTED_OFFICES_KEY, state.selectedOffices, "id");
  },
  setSelectedTabOffice: (state, { key, value }) => {
    Vue.set(state.selectedTabOffice, key, value);
    if (value) {
      ModelLocalStorage.save(key, sha256(value.id.toString()));
    } else {
      ModelLocalStorage.save(key, "");
    }
  },
  setNumberDoctorVisible: (state, value) => {
    state.numberDoctorIsVisible = value;
    ModelLocalStorage.save(NUMBER_DOCTOR_VISIBLE, value);
  },
  setUnselectedRooms(state, value) {
    state.unselectedRooms = value;
  },
  addUnselectedRoom(state, value) {
    const index = state.unselectedRooms.indexOf(value);
    if (index === -1) {
      state.unselectedRooms.push(value);
    }
  },
  removeUnselectedRoom(state, value) {
    const index = state.unselectedRooms.indexOf(value);
    if (index !== -1) {
      state.unselectedRooms.splice(index, 1);
    }
  },
  setDoctorAvatar(state, {path, url}) {
    Vue.set(state.doctorAvatars, path, url);
  },
  setMe(state, value) {
    const index = _.findIndex(state.doctors, { me: true });
    state.doctors[index] = {...state.doctors[index], ...value};
    mutations.setDoctors(state, JSON.parse(JSON.stringify(state.doctors)));
  }
};

const restoreSingle = (key, value, list, setter, commit) => {
  const hashedId = ModelLocalStorage.get(key, "");
  if (value == null && list.length > 0 && hashedId.length > 0) {
    for (let index = 0; index < list.length; index++) {
      const val = list[index];
      if (hashedId == sha256(val.id.toString())) {
        commit(setter, val);
        return val;
      }
    }
    return null;
  } else {
    return value;
  }
};

const actions = {
  async updateDoctor({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit("setDoctor", value);
      resolve();
    });
  },
  async updateDoctorById({ commit }, value) {
    return new Promise((resolve, reject) => {
      const foundIndex = _.findIndex(state.doctors, { id: value });
      if (foundIndex !== -1) {
        commit("setDoctor", state.doctors[foundIndex]);
        resolve();
      } else {
        reject();
      }
    });
  },
  async updateDoctorOrder({ commit }, value) {
    commit("setRightDoctorOrder", value);
  },
  async restoreDoctor({ commit }) {
    return restoreSingle(
      DOCTOR_KEY,
      state.doctor,
      state.doctors,
      "setDoctor",
      commit
    );
  },
  async updateDoctors({ commit }, value) {
    commit("setDoctors", value);
  },
  async updateSelectedEmployees({ commit }, value) {
    commit("setSelectedEmployees", value);
  },
  async restoreSelectedEmployees({ commit }) {
    const hashedEmployeeIds = ModelLocalStorage.get(SELECTED_EMPLOYEES_KEY, []);
    if (
      state.selectedEmployees.length == 0 &&
      state.doctors.length > 0 &&
      hashedEmployeeIds.length > 0
    ) {
      let newSelectedEmployees = [];

      for (
        let hashIndex = 0;
        hashIndex < hashedEmployeeIds.length;
        hashIndex++
      ) {
        const hashedEmployeeId = hashedEmployeeIds[hashIndex];
        for (let index = 0; index < state.doctors.length; index++) {
          const doctor = state.doctors[index];
          if (hashedEmployeeId == sha256(doctor.id.toString())) {
            newSelectedEmployees.push(doctor);
            break;
          }
        }
      }
      commit("setSelectedEmployees", newSelectedEmployees);
      return newSelectedEmployees;
    } else {
      return state.selectedEmployees;
    }
  },
  async addSelectedEmployee({ commit }, value) {
    commit("addSelectedEmployee", value);
  },
  async removeSelectedEmployee({ commit }, value) {
    commit("removeSelectedEmployee", value);
  },
  async updateSelectedEmployee({ commit }, value) {
    commit("updateSelectedEmployee", value);
  },
  async updateOffices({ commit }, value) {
    commit("setOffices", value);
  },
  async addSelectedOffice({ commit }, value) {
    commit("addSelectedOffice", value);
  },
  async removeSelectedOffice({ commit }, value) {
    commit("removeSelectedOffice", value);
  },
  async updateSelectedTabOffice({ commit }, { key, value }) {
    commit("setSelectedTabOffice", { key, value });
  },
  async restoreSelectedTabOffice({ commit }, key) {
    const val = restoreSingle(
      key,
      state.selectedTabOffice[key],
      state.offices,
      "setSelectedTabOffice",
      commit
    );
    return val;
  },
  async reset({ commit }) {
    commit("resetSelectedEmployees");
    commit("setDoctor", null);
    commit("setDoctors", []);
    commit("setOffices", []);
    commit("setSelectedOffices", []);
    commit("setSelectedTabOffice", {});
  },
  async updateNumberDoctorVisible({ commit }, value) {
    commit("setNumberDoctorVisible", value);
  },
  async updateUnselectedRooms({ commit }, value) {
    commit("setUnselectedRooms", value);
  },
  async addUnselectedRoom({ commit }, value) {
    commit("addUnselectedRoom", value);
  },
  async removeUnselectedRoom({ commit }, value) {
    commit("removeUnselectedRoom", value);
  },
  async updateDoctorAvatar({ commit }, value) {
    commit("setDoctorAvatar", value);
  },
  async updateMe({ commit }, value) {
    commit("setMe", value);
  }
};

export const clinic = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default clinic;
