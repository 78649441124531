import TimetableService from "@/services/timetable.service";

const state = {
  scrollMounted: false,
  renderedVisuals: [],
  settings: [],
  dragMode: false,
  isEventMoved: false
}
const getters = {
  getScrollMounted(state) {
    return state.scrollMounted
  },
  getRenderedVisuals(state) {
    return state.renderedVisuals;
  },
  getDragMode(state) {
    return state.dragMode;
  },
  getIsEventMoved(state) {
    return state.isEventMoved;
  }
}
const mutations = {
  setScrollMounted(state, value) {
    state.scrollMounted = value
  },
  setRenderedVisuals(state, value) {
    state.renderedVisuals = {...state.renderedVisuals, ...value}
  },
  setDragMode(state, value) {
    state.dragMode = value
  },
  setIsEventMoved(state, value) {
    state.isEventMoved = value;
  }
}
const actions = {
  async fetchMultiEvents(context, {from_date, to_date}) {
    const selectedEmployees = context.rootGetters['clinic/selectedEmployees']
    const separatedEmployees = selectedEmployees.map((employee) => employee.id).join(",")
    let calendarMode = context.rootGetters['ui/calendarMode']
    const data = await TimetableService.getMultiEvents(
      from_date,
      to_date,
      separatedEmployees,
      calendarMode === 1 ? 9 : 7
    )
    const events = [];
    const firstData = [];
    const countData = [];
    for (let index = 0; index < selectedEmployees.length; index++) {
      const employee = selectedEmployees[index];
      let employeeEvents = { data: [], holidays: [], count: 38, first: 10 };
      for (
        let eventIndex = 0;
        eventIndex < data.length;
        ++eventIndex
      ) {
        const element = data[eventIndex];
        if (element.doctor == employee.id) {
          employeeEvents = element.events;
          break;
        }
        firstData.push(element.events.first)
        countData.push(element.events.count)
      }
      
      const mappedEvents = employeeEvents.data.map((e) => {
        return {
          category: employee.id.toString(),
          ...e
        }
      })
      events.push(...mappedEvents);
      
    }
    const first = Math.min(...firstData);
    const count = Math.max(...countData);
    return {
      events: events,
      first: first,
      count: count
    }
  }
}

export const calendar = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

export default calendar